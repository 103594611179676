<template>
  <Fragment>
    <slot />
  </Fragment>
</template>

<script>
import { Hits } from '@mezereon/ui-components-vue'

export default {
  name: 'MzHitItem',
  mixins: [Hits],
  props: {
    item: {
      type: Object,
      required: true
    },
    score: {
      type: Number,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  }
}
</script>
