<template>
  <component :is="element">
    <slot name="filter" />
    <slot name="body">
      <template v-for="(hit, index) of hits.items">
        <slot :hit="hit" :index="index" :page-total="hits.items.length" />
      </template>
    </slot>
  </component>
</template>

<script>
import { Hits } from '@mezereon/ui-components-vue'

export default {
  name: 'MzHitsContainer',
  mixins: [Hits],
  props: {
    element: {
      type: String,
      default: 'div'
    }
  }
}
</script>
